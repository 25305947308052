<template>
  <div>
    <template v-if="localize">
      <b-tabs v-model="languageTabIndex" class="base-input-tabs">
        <b-tab v-for="(item, index) in languages" :key="index" :title="item" />
      </b-tabs>
      <b-form-group :label="label" :label-for="id || uid">
        <quill-editor v-for="(item, index) in languages" v-show="item === languages[languageTabIndex]" :id="id || uid"
          :key="index" :value="value[item]" @input="handleInput($event, item)" />
      </b-form-group>
    </template>
    <template v-else>
      <b-form-group :label="label" :label-for="id || uid">
        <quill-editor :id="id || uid" :value="value" @input="$emit('input', $event)" />
      </b-form-group>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Quill, quillEditor } from 'vue-quill-editor'


// specify the fonts you would
var fonts = ['Playfair Display', 'Archivo Narrow', 'Open Sans'];
// generate code friendly names
function getFontName(font) {
    return font.toLowerCase().replace(/\s/g, "-");
}
var fontNames = fonts.map(font => getFontName(font));
// add fonts to style
var fontStyles = "";
fonts.forEach(function(font) {
    var fontName = getFontName(font);
    fontStyles += ".ql-snow .ql-picker.ql-font .ql-picker-label[data-value=" + fontName + "]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=" + fontName + "]::before {" +
        "content: '" + font + "';" +
        "font-family: '" + font + "', sans-serif;" +
        "}" +
        ".ql-font-" + fontName + "{" +
        " font-family: '" + font + "', sans-serif;" +
        "}";
});
var node = document.createElement('style');
node.innerHTML = fontStyles;
document.body.appendChild(node);


let toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
  ['blockquote', 'code-block'],

  [{ 'header': 1 }, { 'header': 2 }],               // custom button values
  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
  [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
  [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
  [{ 'direction': 'rtl' }],                         // text direction

  [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

  [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
  [{ 'font': fontNames }],
  [{ 'align': [] }],

  ['clean']                                         // remove formatting button
];

// Add fonts to whitelist
var Font = Quill.import('formats/font');
Font.whitelist = fontNames;
Quill.register(Font, true);

export default {
  name: 'BaseEditor',
  components: {
    quillEditor,
  },
  props: {
    value: {
      type: [String, Object, Array],
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: null,
    },
    localize: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editorOptions: {
        modules: {
          toolbar: toolbarOptions
        },
      },
      languageTabIndex: this.indexCurrentLanguage,
    }
  },
  computed: {
    ...mapGetters({
      languages: 'languages/getLanguages',
      currentLanguage: 'languages/getCurrentLanguage',
    }),
    uid() {
      // eslint-disable-next-line no-underscore-dangle
      return this._uid.toString()
    },
    indexCurrentLanguage() {
      return this.languages.findIndex(language => language === this.currentLanguage)
    },
  },
  methods: {
    handleInput(text, language) {
      const payload = this.localize ? { ...this.value, [language]: text } : text
      this.$emit('input', payload)
    },
  },
}
</script>

<style scoped>
.base-input-tabs>>>.nav-tabs {
  margin-bottom: 4px
}
</style>
